namespace ute.blocks.persistent.storage {
  'use strict';

  class RogersStoreService implements IRogersStoreService {
    static $inject: Array<string> = ['$window'];

    /* @ngInject */
    constructor(private $window: ng.IWindowService) {
      this.putToSession = this.put;
      this.getFromSession = this.get;
      this.removeFromSession = this.remove;
    }

    putToSession: (key: string, value: any) => boolean;
    getFromSession: <T>(key: string) => T;
    removeFromSession: (key: string) => boolean;

    put: (key: string, value: any) => boolean = (key: string, value: any) => {
      this.$window.sessionStorage.setItem(key, angular.isObject(value) || angular.isArray(value) ? angular.toJson(value) : value);
      return true;
    };

    get: <T>(key: string) => T = <T>(key: string) => {
      const value: any = this.$window.sessionStorage.getItem(key);

      try {
        return angular.fromJson(value);
      } catch (e) {
        return value;
      }
    };

    remove: (key: string) => any = (key: string) => {
      this.$window.sessionStorage.removeItem(key);
      return true;
    };

    putToLocal: (key: string, value: any) => boolean = (key: string, value: any) => {
      this.$window.localStorage.setItem(key, angular.isObject(value) || angular.isArray(value) ? angular.toJson(value) : value);
      return true;
    };

    getFromLocal: <T>(key: string) => T = <T>(key: string) => {
      const value: any = this.$window.localStorage.getItem(key);

      try {
        return angular.fromJson(value);
      } catch (e) {
        return value;
      }
    };

    removeFromLocal: (key: string) => any = (key: string) => {
      this.$window.localStorage.removeItem(key);
      return true;
    };

    getLocalStoreService: () => IStoreService = () => ({
      put: this.putToLocal,
      get: this.getFromLocal,
      remove: this.removeFromLocal
    });

    getSessionStoreService: () => IStoreService = () => ({
      put: this.putToSession,
      get: this.getFromSession,
      remove: this.removeFromSession
    });
  }

  angular.module('ute.blocks.persistent.storage').service('ute.blocks.persistent.storage.service', RogersStoreService);
}
