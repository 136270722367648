namespace ute.blocks.persistent.storage {
  'use strict';

  angular.module('ute.blocks.persistent.storage').config(configure);

  configure.$inject = <Array<string>>['$sessionStorageProvider', '$localStorageProvider'];

  /* @ngInject */
  function configure($sessionStorageProvider: any, $localStorageProvider: any) {
    $sessionStorageProvider.setKeyPrefix('');
    $localStorageProvider.setKeyPrefix('');

    $sessionStorageProvider.setDeserializer((value: string) => {
      try {
        return angular.fromJson(value);
      } catch (e) {
        return value;
      }
    });

    $localStorageProvider.setDeserializer((value: string) => {
      try {
        return angular.fromJson(value);
      } catch (e) {
        return value;
      }
    });
  }
}
