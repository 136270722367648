namespace ute.blocks.persistent.storage {
  'use strict';

  export class RogersStoreKeys {
    static IS_LOGIN = 'ute.rogers.signin.service.isLogin';
    static ROGERS_SIGNIN_USER = 'ute.rogers.signin.user';
    static ROGERS_DEEP_LINK_STATE = 'ute.rogers.deep.link.state';
    static ROGERS_SELECTED_PHONE_NUMBER = 'phoneNumber';
    static ROGERS_SELECTED_HOME_PHONE_NUMBER = 'homePhoneNumber';
    static ROGERS_SELECTED_TV_ACCOUNT_NUMBER = 'tvAccountNumber';
    static ROGERS_SELECTED_SHM_ACCOUNT_NUMBER = 'shmAccountNumber';
    static ROGERS_SELECTED_IPTV_ACCOUNT_NUMBER = 'iptvAccountNumber';
    static ROGERS_SELECTED_INTERNET_ACCOUNT_NUMBER = 'internetAccountNumber';
    static ROGERS_SELECTED_ACCOUNT_NUMBER = 'accountNumber';
    static ROGERS_SELECTED_BRITEBILL_ID = 'ute.rogers.selected.britebill.id';
    static ROGERS_SELECTED_ACCOUNT_TYPE_NUMBER = 'accountTypeNumber';
    static ROGERS_SSO_CODE_VERIFICATION = 'ssoCodeVerification';
    static ROGERS_SESSION_TOKEN = 'ute.rogers.signin.sessionToken';
    static ROGERS_REMEMBER_ME = 'ute.rogers.signin.rememberMe';
    static ROGERS_ONEVIEW_REPVIEW = 'isRepView';
    static ROGERS_X_SESSION_TOKEN = 'x_session_token';
  }

  export interface IStoreService {
    /**
     * @param key
     * @param value
     */
    put(key: string, value: any): boolean;

    /**
     * @param key
     */
    get<T>(key: string): T;

    /**
     * @param key
     */
    remove(key: string): any;
  }

  export interface IRogersStoreService extends IStoreService {
    /**
     * @deprecated Please use putToSession
     * @param key
     * @param value
     */
    put(key: string, value: any): boolean;

    /**
     * @deprecated Please use getFromSession
     * @param key
     */
    get<T>(key: string): T;

    /**
     * @deprecated Please use removeFromSession
     * @param key
     */
    remove(key: string): any;

    putToSession(key: string, value: any): boolean;
    getFromSession<T>(key: string): T;
    removeFromSession(key: string): any;

    putToLocal(key: string, value: any): boolean;
    getFromLocal<T>(key: string): T;
    removeFromLocal(key: string): boolean;

    getLocalStoreService(): IStoreService;
    getSessionStoreService(): IStoreService;
  }
}
